/* BannerSection.css */
.banner {
    position: relative;
  }
  
  .banner-img {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .banner-img .desktop-img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .banner-img .mobile-img {
    display: none;
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .banner-img .desktop-img {
      display: none;
    }
  
    .banner-img .mobile-img {
      display: block;
    }
  }
  