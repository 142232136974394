
  
  .client-logo-slider .marquee {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    animation: marquee 15s linear infinite;
  }
  
  .client-logo-slider .marquee-content {
    display: flex;
  }

  .client-logo-slider .client-logo-box img {
   width: unset !important;
  }
  

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  