.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.track {
  display: inline-block;
  white-space: nowrap;
  animation: marquee-animation 50s linear infinite; /* Adjust the duration for speed control */
}

.content {
  display: inline-block;
}

/* Keyframes for the marquee animation */
@keyframes marquee-animation {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-10%);
  }
}
