/* my-account--page---- */

.account-detail-sec {
    width: 100%;
    background: #FFFEFA;
    padding: 70px 0 200px 0;
  }
  
  .account-detail-con {
    max-width: 1310px;
    width: 100%;
    margin: auto;
  }
  
  .account-tab-left .nav-tabs .nav-item.show .nav-link, 
  .account-tab-left .nav-tabs .nav-link.active, 
  .account-tab-left .nav-tabs .nav-link, 
  .account-tab-left .nav-tabs {
    background-color: unset;
    border: unset;
  }
  
  .account-tab-left .nav-tabs {
    display: flex;
    flex-direction: column;
    border-right: 2px solid #F57812CC;
  }
  
  .account-tab-left .nav-tabs .nav-link, 
  .account-tab-left .nav-tabs .nav-link.active {
    color: #4D4638;
    font-family: "Red Hat Text";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    position: relative;
  }
  
  .account-tab-left .nav-tabs .nav-item {
    border: 1px solid #047422;
    width: 100%;
    max-width: 200px;
    text-align: left;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .account-tab-left .nav-tabs .nav-link.active {
    color: #167446;
  }
  
  .account-tab-right {
    background: rgba(227, 237, 230, 0.96);
    height: 100%;
    padding: 20px;
  }
  
  .account-tab-right h6 {
    color: #131D3A;
    font-family: "Playfair Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.64px;
    margin-bottom: 15px;
  }
  
  .account-tab-right p {
    color: rgba(19, 29, 58, 0.80);
    font-family: Sarabun;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.16px;
  }
  
  .account-tab-right h6 a {
    color: #167446;
    transition: all 0.5s ease;
  }
  
  .account-tab-right h6 a:hover {
    color: #131D3A;
  }
  
  .account-tab-right #order .table {
    --bs-table-bg: unset;
  }
  
  .account-tab-right #order .table tr {
    border: 1px solid #167446 !important;
  }
  
  .account-tab-right #order .table tr td a {
    color: #167446;
  }
  
  .account-tab-right #order .table tr td a:hover {
    color: #000;
  }
  
  .account-tab-right #order .table tr td a.view-btn, 
  #subscription .subscription a, 
  #download .download a {
    background: #167446;
    color: #fff;
    padding: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
  }
  
  .account-tab-right #order .table tr td a.view-btn:hover, 
  #subscription .subscription a:hover, 
  #download .download a:hover {
    background: #FFDCC0;
    color: #000;
  }
  
  #subscription .subscription, 
  #download .download {
    background: #F2DDE7;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    border-top: 3px solid #F57812CC;
  }
  
  #subscription .subscription a, 
  #download .download a {
    padding: 5px 12px;
  }
  
  #subscription .subscription p, 
  #download .download p {
    margin-bottom: 0;
  }
  
  #subscription .subscription p span, 
  #download .download p span {
    padding-right: 10px;
  }
  
  #download .download p span i {
    color: #167446;
  }
  
  #address .shipping-address {
    background-color: transparent;
  }
  
  #address .shipping-address .billing-box a i {
    padding-left: 10px;
    padding-right: 0;
  }
  