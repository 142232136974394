/*--------shop-page=----*/

.shop-page-sec .page-banner-heading h2 {
    margin-bottom: 13px !important;
    color: #fff;
    font-family: "Playfair Display";
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
  }
  
  .shop-page-sec .page-banner-heading {
    max-width: 394px;
    width: 100%;
    text-align: right;
    position: relative;
    display: flex;
    justify-content: end;
  }
  
  .shop-page-sec .page-banner-heading h2::after {
    content: "";
    position: absolute;
    border: 1px solid #fff2e8e5;
    left: 0;
    top: 100%;
    min-width: 394px;
    width: 100%;
    animation: border_anim 3s linear forwards;
  }
  
  .shop-page-sec.page-banner {
   
    background-size: cover;
    height: 300px;
  }
  
  .shop-category-sec {
    width: 100%;
    background: #f8ecf2;
    border-bottom: 1px solid rgba(128, 117, 92, 0.4);
    padding: 36px 0;
  }
  
  .shop-category-list {
    max-width: 1310px;
    margin: auto;
    width: 100%;
  }
  
  .shop-list-container-left {
    display: flex;
    gap: 20px;
    /* flex-wrap: wrap; */
    align-items: center;
  }
  
  .shop-list-container-right {
    width: 100%;
    text-align: -webkit-right;
  }
  
  .shop-list-container-left a {
    color: rgba(151, 3, 73, 0.5);
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    width: 100%;
    transition: all 0.5s ease;
  }
  
  .shop-list-container-left a:hover {
    color: #047422;
  }
  
  .shop-dropdown {
    width: 100%;
    max-width: 130px;
  }
  
  .shop-dropdown.price,
  .shop-dropdown.size,
  .shop-dropdown.color {
    width: 100%;
    max-width: 116px;
  }
  
  .shop-dropdown.short_by {
    width: 100%;
    max-width: 140px;
  }
  
  .dropdown__filter,
  .dropdown__select {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  .dropdown__switch:checked + .dropdown__options-filter .dropdown__select {
    transform: scaleY(1);
  }
  .dropdown__switch:checked + .dropdown__options-filter .dropdown__filter:after {
    transform: rotate(-135deg);
  }
  .dropdown__options-filter {
    width: 100%;
    cursor: pointer;
  }
  .dropdown__filter {
    position: relative;
    display: flex;
    padding: 12px 15px;
    font-family: Red Hat Text;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #131d3a;
    border: 1.2px solid #131d3a;
    border-radius: 120px;
    transition: 0.3s;
  }
  

  .dropdown__filter::after {
    position: absolute;
    top: 45%;
    right: 15px;
    content: '';
    width: 10px;
    height: 10px;
    border-right: 2px solid #595959;
    border-bottom: 2px solid #595959;
    transform: rotate(45deg) translateX(-45%);
    transition: 0.2s ease-in-out;
}

.dropdown__select {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
    transform: scaleY(0);
    transform-origin: top;
    font-weight: 300;
    transition: 0.2s ease-in-out;
    z-index: 9;
}

.dropdown__select-option {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    transition: 0.3s;
    color: #131D3A;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
}

.dropdown__select-option:last-of-type {
    border-bottom: 0;
}

.dropdown__select-option:hover {
    background-color: #047422;
    color: #fff;
}


.category-detail-sec {
    background: #FFFEFA;
    width: 100%;
    padding: 50px 0 230px 0;
}

.category-detail-container {
    max-width: 1310px;
    margin: auto;
    width: 100%;
}

.category-box {
    width: 100%;
    border-radius: 40px 0 40px 0 !important;
    overflow: hidden;
}

.category-box .category-img {
    max-width: 280px;
    width: 100%;
    height: 246px;
    margin: auto;
    padding: 11px 0 24px 0;
}

.category-box .category-img:hover img.card-img-top {
    -webkit-transform: rotate(3deg) scale(1);
    transform: rotate(3deg) scale(1);
}

.category-box .category-img img.card-img-top {
    width: 100%;
    height: 100%;
    border-radius: 30px 0 0 0;
    object-fit: cover;
    transition: .3s ease-in-out;
}

.category-box h5 {
    color: #2A312D;
    font-family: "Playfair Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.17px;
    margin-bottom: 19px;
}

.category-box p {
    color: #970349;
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.96px;
    margin-bottom: 29px !important;
}

.category-box  .card-footer  {
    border-radius: 0px 0px 39px 0px;
    background: #ECF6F0;
    padding: 20px 0;
    text-align: center;
    transition: all 0.5s ease;
    
  
}
.category-box .card-footer .cart-btn {
  border: 0;
  background: transparent;
}

.category-box .card-footer .cart-btn  p {
    color: #047422 !important;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 0 !important;
}

.category-box .card-footer  .cart-btn p svg {
    vertical-align: text-top;
}

.category-box  .card-footer:hover {
    background: #047422;
}

.sideBar_shop{
  position: sticky;
  top: 140px;
}

.category-box .card-footer:hover .cart-btn p svg path,
.category-box .card-footer:hover .cart-btn p {
    fill: #fff;
    color: #fff !important;
}

