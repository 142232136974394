/* src/components/Auth.css */

.auth-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .auth-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .auth-input {
    margin-bottom: 15px;
  }
  
  .auth-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .auth-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #228240;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: #1a6830;
  }
  
  .auth-error {
    color: #ff0000;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .auth-success {
    color: #228240;
    text-align: center;
    margin-bottom: 15px;
  }
  