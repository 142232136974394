
.cart-sec-right {
  position: relative;
  z-index: 2;
  background: #FFFEFA;
  height: 100%;
  padding: 122px 0 0 87px;
  width: 100%;
}

.cart-sec-right h2{
  color: #131D3A;
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; 
  letter-spacing: 0.64px;
  border-left: 3px solid #F57812CC;
  padding-left: 18px;
  margin-bottom: 32px;
}

.cart-sec-right .table{
  background: unset;
  --bs-table-bg:unset;
}

.cart-sec-right .table>:not(caption)>*>*{
  padding: 30px 0 !important;

}

.cart-sec-right tbody, td, tfoot, th, thead, tr{
  border-bottom: 1px solid #D9D9D9!important;
}

.cart-sec-right tbody, td, tfoot, th, thead, tr:nth-last-child(1){
  border:0 !important;
}

.cart-sec-right tbody tr td, .cart-sec-right tbody tr td h5{
  color: #131D3A;
  font-family: "Red Hat Text" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
}

.cart-sec-right tr td:nth-last-of-type(1){
  text-align: right;
}

.cart-sec-right tbody tr td.total-amount{
  color: #970349;
  font-weight: 600;
}

.cart-sec-right button.shop-detail-btn{
  border-radius: 10px;
  background: #047422;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: #FFF;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.cart-sec-right a.shop-detail-btn span{
  padding-left: 12px;
}


.cart-sec-right a.shop-detail-btn:hover {
  border: 1px solid #047422;
  background: transparent;
  color: #047422;
}

.cart-sec-right a.shop-detail-btn:hover svg path{
  stroke: #047422 !important;
}


/* 
checkout--page==== */


.checkout-sec{
  background: #FFFEFA;
  width: 100%;
  overflow: hidden;
  padding-bottom: 190px;
}

.checkout-container{
  max-width: 1310px;
  margin: auto;
  width: 100%;
}

.checkout-form .hidden {
  display: none;
}

.checkout-form{
  position: relative;
  padding: 45px 0 139px 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: 700px;
}

.checkout-form::before {
  width: 170%;
  background: #f2dde7;
  height: 100%;
  position: absolute;
  content: '';
  left: -300px;
  top: 0;
  z-index: -1;
}

.checkout-form h1{
  font-family: Playfair Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 42.66px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-left: 18px;
  border-left: 3px solid #F57812CC;
  margin: 45px 0 33px 0;

}

.checkout-form h5{
  font-family: Playfair Display;
  font-size: 21px;
  font-weight: 500;
  line-height: 27.99px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 8px;
}

.checkout-form h5.billing-heading{
  margin-top: 43px;
}

.checkout-form p{
  font-family: Sarabun;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 24px !important;
  margin: 0;

}

.checkout-form .form-control, .checkout-form .form-select{
  height: 50px;
  border-radius: 7px;
  border: 1px solid #131D3A;
  background: rgba(255, 255, 255, 0.20);
  position: relative;
}

/* .checkout-form .form-select::before{
  content: '';
  position: absolute;
  right: 0;
  background: url(../images/down-arrow.svg);
  width: 13px;
  height: 10px;
} */

.checkout-form input::placeholder {
  color: rgba(19, 29, 58, 0.70);
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.48px;
}

/* Hide spinner controls for number input in Chrome, Safari, and Edge */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

/* Hide spinner controls for Firefox */
input[type=number] {
 -moz-appearance: textfield;
}

/* Hide spinner controls for IE/Edge */
input[type=number] {
 appearance: textfield;
}

.is-invalid {
  border-color: red;
}
.invalid-feedback {
  display: none;
  color: red;
}
.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-select:focus,  .form-check-input:focus{
  box-shadow: unset !important;
  border-color: unset !important;
}

.checkout-container .cart-sec-right tr td:nth-last-of-type(1){
  vertical-align: middle;
}

.checkout-container .cart-sec-right tbody tr td,  .checkout-container .cart-sec-right tbody tr td h5{
  font-size: 16px;
}

.checkout-container .cart-sec-right tbody tr td h5 span{
  font-weight: 700;
  font-size: 17px;
}

.checkout-form .payment-sec{
  margin-top: 43px;
}

.checkout-form .payment-sec h5{
  margin-bottom: 35px;
}

.checkout-form .payment-sec p{
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
  margin-bottom: 0 !important;
}

.checkout-form .payment-sec p::after{
  content: "";
  display: block;
  border: 1em solid #fff;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -.75em;
  left: 0;
  margin: -1em 0 0 2em;
}

.payment-sec .form-check{
  padding-bottom: 10px;
}

.payment-sec .form-check label img{
  padding-left: 5px;
}

.form-check-input:checked{
  background-color: #047422 !important;
  border-color: #047422 !important;
}

.cart-sec-right .input-group .btn-outline-secondary{
  background: #047422 !important;
  color: #fff !important;
}

.cart-sec-right .input-group .btn-outline-secondary:hover {
  border: 1px solid #047422 !important;
  background: transparent !important;
  color: #047422 !important;
}










@media (min-width:320px) and (max-width:991px){

  .cart-sec-left, .cart-sec-right{
      padding: 30px 0 !important;
  }

  .cart-sec-right{
      max-width: 753px;
      margin: 0;
  }
  
  .checkout-form{
      padding: 45px 0 30px 0 !important;
  }
  .checkout-form h1{
      margin: 20px 0 !important;
  }

  .cart-sec-right h2{
      margin-bottom: 20px !important;
  }

}

@media (min-width:320px) and (max-width:668px){ 
  .cart-sec-left .cart-details{
      flex-wrap: wrap;
      justify-content: center !important;
      gap: 0 !important;
      flex-direction: column;
      align-items: center;
  }
  .cart-sec-left .cart-details .cart-details-txt p{
      text-align: center;
  }

  .cart-sec-left .cart-details .cart-details-txt{
      padding: 0 !important;
  }

  .cart-sec-right h2{
      margin-bottom: 10px;
  }

  .cart-sec-left h2{
      margin: 25px 0 !important;
  }

      .cart-details-amount{
          flex-wrap: wrap;
          justify-content: center;
      }
      .cart-sec-left::after{
          display: none;
      }
      
}


@media (min-width:320px) and (max-width:480px){

  .payment-sec .form-check label img{
      width: 35px;
  }
  
}

