.shop-cart-sec {
    background: #FFFEFA;
    width: 100%;
    overflow: hidden;
    padding-bottom: 190px;
}

.shop-cart-container {
    max-width: 1310px;
    margin: auto;
    width: 100%;
}

.cart-sec-left {
    position: relative;
    padding: 45px 0 139px 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.cart-sec-left::before {
    width: 150%;
    background: rgba(227, 237, 230, 0.96);
    height: 100%;
    position: absolute;
    content: '';
    left: -300px;
    top: 0;
    z-index: -1;
}



.cart-sec-left .cart-details {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    border-radius: 20px 0px;
    border: 1px solid rgba(128, 117, 92, 0.40);
    background: rgba(255, 255, 255, 0.90);
    margin-bottom: 19px;
    position: relative;
    max-width: 753px;
}

.cart-sec-left .cart-details:last-child {
    margin-bottom: 0;
}

.cart-sec-left .cart-details img {
    max-width: 106px;
    width: 100%;
    height: 106px;
    border-radius: 14px 0px 0px 0px;
    border: 1px solid rgba(128, 117, 92, 0.50);
    background: url('/public/loader.png') lightgray 0px -29.613px / 100% 128.6% no-repeat;
    margin: 16px;
}

.cart-sec-left .cart-details .cart-details-txt {
    flex: 1;
    padding: 16px 0 32px 0;
}

.cart-sec-left .cart-details .cart-details-txt h4 {
    color: #970349;
    font-family: "Playfair Display";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; 
    letter-spacing: 1.04px;
}

.cart-sec-left .cart-details .cart-details-txt p {
    color: #2A312D;
    font-family: "Playfair Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.17px;
    margin-bottom: 15px;
}

.cart-sec-left .cart-details .cart-details-txt h4 span {
    color: #969593;
    font-family: "Playfair Display";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-decoration-line: strikethrough;
}

.cart-sec-left .cart-details a.cart-remove-btn {
    color: rgba(151, 3, 73, 0.96);
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    text-decoration: underline !important;
    padding: 19px 25px;
}

.cart-details-amount {
    display: flex;
    gap: 10px;
    align-items: center;
}

.cart-sec-left h2 {
    color: #2A312D;
    font-family: "Playfair Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    border-left: 3px solid #F57812CC;
    padding-left: 18px;
    margin: 45px 0 40px 0;
}

.cart-sec-right {
    position: relative;
    z-index: 2;
    background: #FFFEFA;
    height: 100%;
    padding: 122px 0 0 87px;
    width: 100%;
}

.cart-sec-right h2 {
    color: #131D3A;
    font-family: "Playfair Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; 
    letter-spacing: 0.64px;
    border-left: 3px solid #F57812CC;
    padding-left: 18px;
    margin-bottom: 32px;
}

.cart-sec-right .table {
    background: unset;
    --bs-table-bg: unset;
}

.cart-sec-right .table > :not(caption) > * > * {
    padding: 30px 0 !important;
}

.cart-sec-right tbody, td, tfoot, th, thead, tr:nth-last-child(1) {
    border: 0 !important;
}

.cart-sec-right tbody tr td {
    color: #131D3A;
    font-family: "Red Hat Text" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
}

.cart-sec-right tr td:nth-last-of-type(1) {
    text-align: right;
}

.cart-sec-right tbody tr td.total-amount {
    color: #970349;
    font-weight: 600;
}

.cart-sec-right a.shop-detail-btn {
    border-radius: 10px;
    background: #047422;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #FFF;
    font-family: "Red Hat Text";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; 
    text-transform: uppercase;
    transition: all 0.5s ease;
}

.cart-sec-right a.shop-detail-btn span {
    padding-left: 12px;
}

.cart-sec-right a.shop-detail-btn:hover {
    border: 1px solid #047422;
    background: transparent;
    color: #047422;
}

.cart-sec-right a.shop-detail-btn:hover svg path {
    stroke: #047422 !important;
}

.cart-sec-left a.shop-back-btn {
    top: 10px;
}

@media (min-width: 320px) and (max-width: 991px) {
    .cart-sec-left, .cart-sec-right {
        padding: 30px 0 !important;
    }

    .cart-sec-right {
        max-width: 753px;
        margin: 0;
    }
}

@media (min-width: 320px) and (max-width: 668px) {
    .cart-sec-left::before {
        left: -20px !important;
    }

    .cart-sec-left .cart-details {
        flex-wrap: wrap;
        justify-content: center !important;
        gap: 0 !important;
        flex-direction: column;
        align-items: center;
    }

    .cart-sec-left .cart-details .cart-details-txt p {
        text-align: center;
    }

    .cart-sec-left .cart-details .cart-details-txt {
        padding: 0 !important;
    }

    .cart-sec-right h2 {
        margin-bottom: 10px;
    }

    .cart-sec-left h2 {
        margin: 25px 0 !important;
    }

    .cart-details-amount {
        flex-wrap: wrap;
        justify-content: center;
    }

    .cart-sec-left::after {
        display: none;
    }
}
