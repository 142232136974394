.sideBar_bx p.paragraph_p2, .sideBar_shop .paragraph_p2.sideHdng{
    font-weight: 600;
}

.price_range_slider .price_range .paragraph_p3 {
    font-size: 20px;
    font-weight: 600;
}

.rc-slider-handle {
    border: solid 2px #82765c;
}
.rc-slider-track, .rc-slider-tracks {
    background-color: #82765c;
}

.rc-slider-handle:hover {
    border-color: #82765c;
}
.rc-slider-handle:focus-visible{
    box-shadow: unset;
    border-color: #82765c;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    box-shadow: unset;
    border-color: #82765c;
}



.sideBar_shop .paragraph_p2.sideHdng {
    text-transform: uppercase;
}
@media only screen and (min-width: 1500px) and (max-width: 1699px) {
    .sideBar_shop .paragraph_p2.sideHdng {
        margin-bottom: 19px;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1699px) {
    .sideBar_bx p.paragraph_p2, .sideBar_shop .paragraph_p2.sideHdng {
        margin-bottom: 16px;
    }
}
.sideBar_bx p.paragraph_p2, .sideBar_shop .paragraph_p2.sideHdng {
    margin-bottom: 30px;
    font-weight: 600;
}
.shop_sidebar_filter .paragraph_p2 {
    font-size: 20px !important;
}

@media only screen and (min-width: 1500px) and (max-width: 1699px) {
    .widget_sidebar {
        margin-bottom: 30px;
    }
}

.widget_sidebar {
    margin-bottom: 30px;
}

.cat_list_check {
    position: relative;
    margin-bottom: 26px;
}

.cat_list_check input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.cat_list_check label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.budge_bx,
.list_grid {
    align-items: center;
    display: flex;
}

.cat_list_check label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #82765c;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 2px;
}

[dir="rtl"] .cat_list_check label:before {
    margin-left: 10px;
    margin-right: unset;
}

.cat_list_check input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 8px;
    height: 16px;
    border: solid #82765c;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


[dir="rtl"] .cat_list_check input:checked+label:after {
    right: 9px;
    left: unset;
}

.cat_list_check input:checked+label,
.price_range_slider .price_range span#from,
.price_range_slider .price_range span#to {
    font-weight: 600;
}

.variant_color .color_list {
    display: flex;
    gap: 15px;
    position: relative;
}

.color_list.cat_list_check label::before {
    margin-right: 15px;
}
